@charset "utf-8";
html, body, h1, p {
    font-family: "Open Sans Regular", Helvetica, Arial, "sans-serif";
}

h2{
	margin-top: 0;
}

#more1 {display: none;}
#more2 {display: none;}
#more3 {display: none;}
#more4 {display: none;}

.clearfix:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

.header{
    background-image: linear-gradient(#292D6C, #1E1F58);
    height: 65px;
    color: #FFFFFF;
}
.header-nav-home{
    width: auto;
    float: left;
	padding: 0px 20px;
}
.header-nav-home h1{
    font-size: 30px;
	line-height: 20px;
}
.header-nav-button{
    width: auto;
    float: right;
    padding: 20px 20px;
}
.header-nav-button a{
	font-size: 18px;
    color: #FFFFFF;
}
.header-nav-button a:hover{
	font-size: 18px;
    color: #CDCBCB;
	font-family: "Open Sans Semibold";
	text-decoration: none;
}

.button {
	display: inline-block;
	border-radius: 7px;
	padding: 8px 10px;
	border: 1px solid #D5D5D5;
	color: white;
	background-image: linear-gradient(#292D6C, #1E1F58);
	font: 15px/8px "Open Sans Semibold";
}

.button:hover {
    border: 1px inset #D5D5D5;
    background-image: linear-gradient(rgba(67,72,134,1.00),rgba(80,85,152,1.00));
	cursor: pointer;
}

.button--state-inactive {
	background-image: linear-gradient(#C2C1C1, #A9A9A9);
	border-color: #A9A9A9;
}
.button-delete {
	background-image: linear-gradient(#D91F26, #BE1A20);
}

.main-containter-info{
    width: 100%;
    padding: 25px;
}
.main-containter{
    width: 100%;
    padding: 25px;
	display: inline-block;
}
.main-item-container{
	padding: 10px;
}
.main-item{
	border-style: solid;
	border-color: #1A1A1A;
	border-width: medium;
	border-width: 2px;
	height: 204px;
	width: 100%;
}
.main-content{
	height:200px;
	width: 50%;
	float: left;
}
.main-content-header{
    color: #1A1A1A;
	font-size: 15px;
    font-family: "Open Sans Semibold";
    padding: 10px 10px 2px 10px;
	margin: 2px 0px;
  	white-space: nowrap; 
  	overflow: hidden;
  	text-overflow: ellipsis; 
	}
.main-content-desc{
	color: #1A1A1A;
	font-size: 10px;
	padding: 2px 10px;
	margin: 2px 0px 25px 0px;
  	white-space: nowrap; 
  	overflow: hidden;
  	text-overflow: ellipsis; 
}
.main-content-button-edit-container{
	width: 100%;
	padding: 10px;
}
.main-content-button-edit{
    width: 100%;
	height: 40px;
	font-size: 20px;
	line-height: 20px;
    text-align: center;
}
.main-content-button-symbol-container{
	width: 100%;
	padding: 10px;
	display: flex;
	justify-content: space-between;
}
.main-content-button-symbol{
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.main-content-button-symbol-image{
	width: 30px;
	height: 30px;
}
.main-media{
	height: 200px;
	width: 50%;
	float: right;
	display: flex;
	justify-content: center;
	align-items: center;
}
.main-media-image{
    width: 80%;
    height: auto;
}
.main-item-new-project{
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.main-item-new-project img{
	width: 50%;
}

.editor-container {
	height: 70vh;
	overflow: scroll;
	padding: 10px;
}

.editor-container-price {
	overflow: auto;
}

.editor-options-container {
    min-height: 60px;
    width: 100%;
    border: 2px solid #393939;
	padding: 2px;
	margin: 5px 0;
}

.editor-options-title-container {
	height: 55px;
	word-wrap: normal;
	display: flex;
	align-items: center;
	font: 18px/18px "Open Sans Extrabold";
}

.editor-options-title-container p {
	margin: 2px;
}

.editor-options-arrow-container {
	height: 55px;
	display: flex;
	justify-content: right;
	align-items: center;
}

.editor-options-arrow-container:hover {
	cursor: pointer;
}
	
.editor-options-arrow-container img{
	max-height: 30px;
}

.editor-options-dropdown-container {
    border: 2px solid #393939;
	padding: 4px;
	margin: 2px 0;
}

.editor-options-dropdown-item {
	height: 50px;
	display: flex;
	align-items: center;
}

.editor-options-dropdown-image {
	height: 100%;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.editor-options-image {
	max-height: 90%;
	max-width: 90%;
}

.editor-options-dropdown-info {
	padding: 0;
}

.editor-options-dropdown-info #title {
	font-size: 14px;
	line-height: 14px;
	margin-bottom: 4px;
}

.editor-options-dropdown-info #description {
	font-size: 10px;
	line-height: 12px;
}

.editor-options-dropdown-info p {
	line-height: 15px;
	margin: 0px;
	padding: 0px;
}

.dropdown-box {
    font-size: 9px;
    line-height: 12px;
    background-color: #F1F0F0;
	color: #515151;
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: thin solid #C2C1C1;
	white-space: nowrap;
}

.editor-options-dropdown-button {
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.editor-options-button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px;
	width: 75%;
	max-width: 35px;
	min-width: 20px;
}

.editor-options-dropdown-button img {
	width: 100%;
}

.editor-image-container {
	height: 60vh;
	width: 100%;
	display: flex;
	align-items: center;
}

.editor-image-container img{
	height: 40vh;
	width: auto;
}

.editor-image-buttons {
	width: 150px;
}

.editor-image-buttons div {
	margin: 10px;
	width: 100px;
}

.editor-price-container {
	height: 450px;
    width: 100%;
    border: 2px solid #393939;
	padding: 2px;
	margin: 5px 0;
}

.editor-price-title {
    font-size: 20px;
    line-height: 20px;
    padding: 10px;
    border-bottom: medium solid #292D6C;
}

.editor-price-title p {
	margin: 0;
}

.editor-price-breakdown {
	height: 300px;
	overflow: scroll;
	padding: 5px 0;
}

.editor-price-breakdown-item {
	height: 35px;
	padding: 5px 0;
	margin: 5px;
	border-bottom: 1px solid #292D6C;
	display: flex;
	align-items: center;
	line-height: 15px;
}

.editor-price-breakdown-item-name {
	padding: 0 5px;
}

.editor-price-breakdown-item-price {
	text-align: end;
	padding: 0 5px 0 0;
}

.editor-price-breakdown-item-button-container {
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.editor-price-breakdown-item-button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px;
	width: 100%;
}

.editor-price-breakdown-item-button img {
	width: 100%;
}

.editor-price-total {
    font-size: 20px;
    line-height: 20px;
    padding: 10px;
    border-top: medium solid #292D6C;
	text-align: end;
}

.editor-price-total p {
	margin: 0;
}

.editor-price-buttons {
	padding: 0;
	display: flex;
	justify-content: center;
	margin-top: 5px;
}

.editor-price-button-symbol {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	padding: 2px;
}

.editor-price-button-symbol img{
	margin: 0;
	width: 100%;
}

.editor-price-button-text {
	display: flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	width: 125px;
	height: 40px;
	margin: 0 2%;
}

.footer{
	height: 100px;
	background-image: linear-gradient(#1E1F58, #292D6C);
    overflow-y: auto;
}
